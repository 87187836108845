import { memo, useEffect, useState } from "react";
import "./style.scss";
import { message, Upload } from "antd";
import { images } from "@components/images";
import Aries from "../../locale/en-Us.json";
import { commanGetApi, deleteUploadFileApi } from "@utils/api/commonApi";
import ButtonGroupFooter from "@components/buttonGroupFooter";
import { apiBaseUrl } from "@utils/api/api";
import { downloadBase64File } from "@utils/utils";
import { setNotification } from "redux/reducers";
import { notificationTypes } from "@utils/constants";
import { useDispatch } from "react-redux";
const { Dragger } = Upload;

const FileUploadComponentV2 = ({
  heading = "",
  subheading = "",
  title = "Drag and drop or choose a file to upload",
  subtitle = "",
  supportType,
  backStep,
  nextStep,
  downloadSample = false,
  getFilesApiUrl,
  postFilesApiUrl,
  sampleApiUrl,
  extraParams,
  hideCCH = false,
  multifile = false,
  showButton = true,
  analyseData,
  enableDefaultNext = false,
  stopGetAPi = false,
}) => {
  const [fileList, setFileList] = useState([]);
  const [enableNextButton, setEnableNextButton] = useState(
    enableDefaultNext ? !enableDefaultNext : true
  );
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("api call");
    if (getFilesApiUrl) {
      fetchFileData();
    }
  }, [getFilesApiUrl]);

  const fetchFileData = async () => {
    try {
      const data = await commanGetApi(
        `${apiBaseUrl}${getFilesApiUrl}`,
        dispatch
      );

      // Transform API data to match Ant Design Upload format
      const transformedData = data.map((file) => ({
        uid: file.id.toString(),
        name: file.originalFileName,
        status: "done",
        bucketFileId: file.bucketFileId,
        response: [{ bucketFileId: file.bucketFileId }],
      }));
      if (transformedData.length > 0) {
        setEnableNextButton(false);
      }
      setFileList(transformedData);
    } catch (error) {
      console.error("Error fetching file list:", error);
      message.error("Failed to load existing files");
    }
  };

  // Create query string from extraParams object
  const createQueryString = (params) => {
    if (!params || Object.keys(params).length === 0) return "";

    // Start with ? if we have parameters
    const queryString = Object.entries(params)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryString ? `&${queryString}` : "";
  };
  const extraQueryString = createQueryString(extraParams);

  const [uploadingFiles, setUploadingFiles] = useState(new Set());

  const props = {
    name: "files",
    multiple: multifile,
    accept: supportType || undefined,
    action: `${apiBaseUrl}${postFilesApiUrl}${extraQueryString}`,
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("userData")).token}`,
    },
    onChange(info) {
      const { file, fileList } = info;
      setFileList(fileList);

      const newUploadingFiles = new Set(uploadingFiles);

      if (file.status === "uploading") {
        newUploadingFiles.add(file.uid);
      } else if (file.status === "done" || file.status === "error") {
        newUploadingFiles.delete(file.uid);
      }

      setUploadingFiles(newUploadingFiles);
      if (newUploadingFiles.size === 0) {
        setEnableNextButton(false);
        if (getFilesApiUrl) {
          fetchFileData();
        } // Call API to refresh uploaded files
      } else {
        setEnableNextButton(true);
      }
    },
    onRemove: async (file) => {
      try {
        if (
          file.bucketFileId ||
          (file.response && file.response[0]?.bucketFileId)
        ) {
          const fileId = file.bucketFileId || file.response[0].bucketFileId;
          await deleteUploadFileApi(fileId);
        }

        setFileList((prevList) =>
          prevList.filter((item) => item.uid !== file.uid)
        );
        setUploadingFiles((prev) => {
          const newSet = new Set(prev);
          newSet.delete(file.uid);
          return newSet;
        });

        return true;
      } catch (error) {
        console.error("Error removing file:", error);
        message.error("Failed to remove file");
        return false;
      }
    },
  };

  const downloadSampleFile = async () => {
    const file = await commanGetApi(sampleApiUrl);
    downloadBase64File(file);
    dispatch(
      setNotification({
        type: notificationTypes.SUCCESS,
        message: "File Downloaded Successfully",
        placement: "bottom",
      })
    );
  };
  const buttonGroup = {
    left: [],
    right: [
      {
        text: "Back",
        className: "back-button",
        fn: () => {
          backStep();
        },
        imageLink: images["arrow-left.svg"],
      },
      {
        text: Aries.BUTTON_GLOBAL_NEXT,
        rightImageLink: images["arrow-right.svg"],
        className: "primary-button",
        disabled: enableNextButton,
        fn: nextStep,
      },
    ],
  };
  return (
    <div className="file-upload-container">
      <div className="file-wrapper">
        <div className="heading-wrapper">
          {heading && <div className="file-heading">{heading}</div>}
          {subheading && (
            <div className="file-subheading">
              {subheading}
              {"  "}
              {downloadSample && (
                <img
                  className="sample-download-btn"
                  src={images["download-text.svg"]}
                  alt="Download"
                  onClick={downloadSampleFile}
                />
              )}
            </div>
          )}
        </div>
        <Dragger {...props} fileList={fileList}>
          <div className="file-box">
            <div className="file-icon">
              <img src={images["upload.svg"]} alt="upload" />
            </div>
            <div className="title">{title}</div>
            <div className="subtitle">{subtitle}</div>
            <button className="button primary-button-border-2">
              Browse File
            </button>
          </div>
        </Dragger>
        {analyseData && (
          <button
            className="button primary-button width-50"
            onClick={analyseData}
          >
            {" "}
            Analyse{" "}
          </button>
        )}
        {hideCCH && (
          <div className="cchbutton-wrapper">
            <img src={images["divider.svg"]} alt="divider" />
            <button className="cch-button button ">
              <img src={images["cch.svg"]} alt="CCH Icon" />
              {Aries.IMPORT_FROM_CCH}
            </button>
          </div>
        )}
      </div>
      {showButton && (
        <ButtonGroupFooter
          className="fixed-button-group"
          buttonGroupObject={buttonGroup}
        />
      )}
    </div>
  );
};

export default memo(FileUploadComponentV2);
