import React, { useEffect, useState } from "react";
import { Button, Empty, Form, Table } from "antd";
import { EditableCell, EditableRow } from "./sub/EditableCell";
import Aries from "../../locale/en-Us.json";
import images from "../../components/images/images";
import ButtonGroupFooter from "../../components/buttonGroupFooter";
import { patchTrialBalanceMappingApi } from "./api";
import { convertToUSDString } from "../../utils/utils";

const TrialBalanceMappingTable = ({
  tableLoading,
  metaData,
  projectId,
  setMetaData, // Add this prop for updating metaData
  backStep,
  nextStep,
  setState,
  setFileKey,
}) => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    setEditingKey(record.key);
  };

  const handleSave = (key, newValue) => {
    // Update dataSource
    const newData = [...dataSource];
    const index = newData.findIndex((item) => item.key === key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        Mapping: newValue,
      });
      setDataSource(newData);

      // Update metaData
      if (metaData && metaData.metaData && setMetaData) {
        const newMetaData = {
          ...metaData,
          metaData: {
            ...metaData.metaData,
            values: metaData.metaData.values.map((row, rowIndex) => {
              if (rowIndex === index) {
                const mappingIndex =
                  metaData.metaData.headers.indexOf("Mapping");
                if (mappingIndex !== -1) {
                  const newRow = [...row];
                  newRow[mappingIndex] = newValue;
                  return newRow;
                }
              }
              return row;
            }),
          },
        };
        try {
          const resp = patchTrialBalanceMappingApi(
            projectId,
            newMetaData.metaData
          );
        } catch (error) {
          console.error("Error in PATCH request:", error);
        }
        setMetaData(newMetaData);
      }
    }
    setEditingKey("");
  };

  const columns = metaData?.metaData?.headers?.map((header) => ({
    title: header,
    dataIndex: header,
    key: header,
    width: header === "Mapping" ? 300 : undefined,
    render: (text, record) => {
      const editable = isEditing(record);

      if (header === "Mapping") {
        return editable ? (
          <EditableCell
            editing={true}
            dataIndex={header}
            title={header}
            record={record}
            options={metaData.allMappings}
            onSave={handleSave}
          >
            {text}
          </EditableCell>
        ) : (
          <div
            className="editable-cell-value-wrap"
            style={{
              paddingRight: 24,
              cursor: "pointer",
              maxWidth: "500px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            onClick={() => edit(record)}
          >
            {text}
          </div>
        );
      } else if (header === "Current Period" || header === "Prev Period") {
        return convertToUSDString(Number(text)) || "-";
      }
      return text;
    },
  }));

  const mergedColumns = columns?.map((col) => {
    if (col.dataIndex !== "Mapping") {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        options: metaData.allMappings,
        onSave: handleSave,
      }),
    };
  });

  useEffect(() => {
    if (metaData && metaData.metaData?.values) {
      setDataSource(
        metaData.metaData?.values?.map((row, index) => {
          const rowObject = {};
          metaData.metaData?.headers.forEach((header, colIndex) => {
            rowObject[header] = row[colIndex];
          });
          rowObject.key = `row-${index}`;
          return rowObject;
        })
      );
    }
  }, [metaData?.metaData?.values]);

  const buttonGroup = {
    left: [
      // {
      //   text: Aries.BUTTON_GLOBAL_SAVE_DRAFT,
      //   className: "white-button",
      //   imageLink: images["save-add.svg"],
      //   fn: () => {}
      // },
    ],
    right: [
      {
        text: Aries.BUTTON_GLOBAL_BACK,
        className: "back-button",
        fn: backStep,
        imageLink: images["arrow-left.svg"],
      },
      {
        text: Aries.BUTTON_GLOBAL_NEXT,
        className: "primary-button",
        fn: nextStep,
        rightImageLink: images["arrow-right.svg"],
      },
    ],
  };

  return (
    <div className="trail-balance-mapping-table">
      <div className="table-heading">
        <h5>Trial Balance and Chart of Account with Fiscalix Mapping</h5>
        <Button
          className="primary-button button"
          onClick={() => {
            setState((prevRecord) => ({
              ...prevRecord,
              step: 4,
            }));
            setFileKey("TRIAL_BALANCE");
          }}
        >
          Re-upload
        </Button>
      </div>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              row: EditableRow,
              cell: EditableCell,
            },
          }}
          dataSource={dataSource}
          columns={mergedColumns}
          pagination={false}
          scroll={{ x: "1230px" }}
          rowHoverable={false}
          loading={tableLoading}
          rowClassName="editable-row"
          locale={{
            emptyText: tableLoading?.spinning ? (
              <div className="empty-table"></div>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ),
          }}
        />
      </Form>

      <ButtonGroupFooter
        buttonGroupObject={buttonGroup}
        className={"fixed-button-group"}
      />
    </div>
  );
};

export default TrialBalanceMappingTable;
