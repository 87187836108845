import React from "react";
import ReactDOM from "react-dom/client";
// import "@mescius/spread-sheets/styles/gc.spread.sheets.excel2013white.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { registerLicense } from "@syncfusion/ej2-base";
import { Provider } from "react-redux"; // Import the Provider
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import * as amplitude from "@amplitude/analytics-browser";
import * as Sentry from "@sentry/react";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY);

const CURR_ENV = process.env.REACT_APP_BASE_URL;
const AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY;

const baseHandler = (BASE) => {
  if (BASE === "https://api.aries.fiscalix.com") {
    return "production";
  } else if (BASE === "https://api.aries.staging.fiscalix.com") {
    return "staging";
  } else {
    return "development";
  }
};

Sentry.init({
  dsn: "https://b8f7f0616e7f77694d650fda4499979b@o4508199005716480.ingest.us.sentry.io/4508199104937984",
  integrations: [],
  environment: baseHandler(CURR_ENV),
  tracesSampleRate: 1.0,

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0,
});

amplitude.init(AMPLITUDE_KEY, null, {});

const sessionReplayTracking = sessionReplayPlugin({
  sampleRate: 1,
});

amplitude.add(sessionReplayTracking);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
