export const UNITSMAP = [
  {
    value: "$",
    label: "$",
  },
  {
    value: "THOUSANDS",
    label: "$, thousands",
  },
  {
    value: "MILLIONS",
    label: "$, millions",
  },
  {
    value: "BILLIONS",
    label: "$, billions",
  },
];
export const SHEET_NAME_MAP = [
  {
    value: "BALANCE_SHEET",
    label: "Balance Sheet",
  },
  {
    value: "INCOME_STATEMENT",
    label: "Income Statement",
  },
];
export const DURATION_MAP = [
  {
    value: "3 month ended",
    label: "Three month ended",
    key: "Three month ended",
  },
  {
    value: "6 month ended",
    label: "Six month ended",
    key: "Six month ended",
  },
  {
    value: "9 month ended",
    label: "Nine month ended",
    key: "Nine month ended",
  },
  {
    value: "12 month ended",
    label: "Twelve month ended",
    key: "Twelve month ended",
  },
];
export const decoderStatusTrackTableColumnKeys = {
  DATE: "date",
  STATUS: "decodedJsonStatus",
  SOURCE: "source",
  ACTION: "action",
  DOCUMENT: "docType",
};

const defaultStyle = {
  color: "var(--Colors-GRAY-500)",
  // padding: "3px 20px",
  borderRadius: "30px",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "22px",
};
export const decoderStatus = [
  {
    status: "DECODED",
    value: "DECODED",
    style: {
      backgroundColor: "#B5DFB7",
      border: ".5px solid #3FAD46",
      dotColor: " #3FAD46",
      ...defaultStyle,
    },
  },
  {
    status: "DRAFT SAVED ",
    value: "DRAFTED",
    style: {
      backgroundColor: "#E7F5E8",
      border: ".5px solid #5EBB64",
      dotColor: "#5EBB64",
      ...defaultStyle,
    },
  },
  {
    status: "YET TO BEGIN",
    value: "YET_TO_BEGIN",
    style: {
      backgroundColor: "var(--Colors-CREAM-100)",
      border: ".5px solid var(--Colors-GRAY-100)",
      dotColor: "var(--Colors-GRAY-100)",
      ...defaultStyle,
    },
  },
];
export const PROJECT_ID = "projectId";
