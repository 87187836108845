import React, { useState, useRef, useEffect } from "react";
import { Form, Input, Select, message } from "antd";
import SheetComponent from "../../components/sheetComponent";
import Aries from "../../locale/en-Us.json";
import images from "../../components/images/images";
import ButtonGroupFooter from "../../components/buttonGroupFooter";
import {
  saveProgramGuidanceDataApi,
  saveProgramDataV2Api,
  saveDataV2Api,
} from "./api";
import { useDispatch } from "react-redux";
import { EMPTY_LOADER_CONFIG } from "../../utils/constants";
import { setloader } from "../../redux/reducers";
import "../../pages/programs/style.scss";
import { isPrivateOrInternationalCompany } from "@utils/utils";

const { Option } = Select;

const DecoderGuidance = ({
  projectId,
  fileKey,
  data,
  nextStep,
  backStep,
  companyType,
  financialYear,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const spreadsheetRef = useRef(null);
  const [guidanceData, setGuidanceData] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);
  const [sheetIndex, setSheetIndex] = useState(0);

  // useEffect(() => {
  //   const initialGuidanceData = data?.guidanceData || [];
  //   setGuidanceData(initialGuidanceData);
  //   // Set initial form values
  //   const initialValues = {};
  //   initialGuidanceData?.forEach((item) => {
  //     initialValues[item.key] = item?.value?.text || item.value;
  //   });
  //   form.setFieldsValue(initialValues);

  //   if (data?.guidanceData) {
  //     dispatch(setloader(EMPTY_LOADER_CONFIG));
  //   }
  //   if (!data) {
  //     spreadsheetRef.current = null;
  //   }
  // }, [data, dispatch, form]);
  useEffect(() => {
    const initialGuidanceData = data?.guidanceData || [];
    setGuidanceData(initialGuidanceData);

    // Find the first text-based input field
    const firstTextFieldIndex = initialGuidanceData.findIndex(
      (item) => item.inputType !== "DROPDOWN"
    );

    setActiveIndex(firstTextFieldIndex !== -1 ? firstTextFieldIndex : 0);

    // Set initial form values
    const initialValues = {};
    initialGuidanceData?.forEach((item) => {
      initialValues[item.key] = item?.value?.text || item.value;
    });
    form.setFieldsValue(initialValues);

    if (data?.guidanceData) {
      dispatch(setloader(EMPTY_LOADER_CONFIG));
    }
    if (!data) {
      spreadsheetRef.current = null;
    }
  }, [data, dispatch, form]);

  useEffect(() => {
    validateForm();
  }, [guidanceData]);

  const validateForm = () => {
    const isValid = guidanceData.every(
      (field) =>
        field.value &&
        (typeof field.value === "string" ||
          (field.value.text !== undefined && field.value.text !== ""))
    );
    setIsFormValid(isValid);
  };

  const onFinish = (values) => {
    message.success("Form submitted successfully!");
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Form submission failed!");
    setIsFormValid(false);
  };

  const handleFieldChange = (key, value) => {
    if (key === "Work Sheet") {
      activeSheet(value);
    }
    setGuidanceData((prevData) =>
      prevData.map((item) =>
        item.key === key
          ? {
              ...item,
              value:
                typeof item.value === "string"
                  ? value
                  : { ...item.value, text: value },
            }
          : item
      )
    );
  };

  const getHandleIndexValue = (index, args, arrayValue) => {
    const rowIndex = parseInt(index[1]);
    const cellIndex = parseInt(index[0]);
    const text = arrayValue;
    if (text[rowIndex] == null) {
      return;
    }

    setGuidanceData((prevData) => {
      const newData = [...prevData];
      if (newData[activeIndex]) {
        newData[activeIndex] = {
          ...newData[activeIndex],
          value: {
            rowIdx: cellIndex,
            cellIdx: rowIndex,
            text: text[rowIndex] || "",
          },
        };
        // Update form field value
        form.setFieldsValue({
          [newData[activeIndex].key]: text[rowIndex] || "",
        });
      }
      return newData;
    });

    // Move to the next form item
    setActiveIndex((prevIndex) => (prevIndex + 1) % guidanceData.length);
  };

  const buttonGroup = {
    left: [
      // {
      //   text: Aries.BUTTON_GLOBAL_SAVE_DRAFT,
      //   className: "secondary-buttons",
      //   imageLink: images["save-add.svg"],
      // },
    ],
    right: [
      {
        text: "Back",
        className: "back-button",
        fn: backStep,
        imageLink: images["arrow-left.svg"],
      },
      {
        text: Aries.BUTTON_GLOBAL_NEXT,
        rightImageLink: images["arrow-right.svg"],
        className: "primary-button",
        disabled: !isFormValid,
        fn: async () => {
          if (isFormValid) {
            await saveGuidance(fileKey);
            await nextStep();
          }
        },
      },
    ],
  };

  const saveGuidance = async (fileKey) => {
    const result = await saveDataV2Api(
      `/v1/program-docs/${projectId}/guidance?key=${encodeURIComponent(fileKey)}${isPrivateOrInternationalCompany(companyType) ? "&financialYear=" + financialYear : ""}`,
      guidanceData,
      dispatch
    );
  };
  // const renderFormItem = (field, index) => {
  //   const isActive = index === activeIndex;
  //   const fieldValue =
  //     typeof field.value === "string" ? field.value : field.value?.text || "";

  //   switch (field.inputType) {
  //     case "DROPDOWN":
  //       return (
  //         <Select
  //           placeholder={`Select ${field.label}`}
  //           onChange={(value) => handleFieldChange(field.key, value)}
  //           value={fieldValue || field?.allowedValues?.options?.[0]}
  //           className={isActive ? "active-field" : ""}
  //         >
  //           {field.allowedValues.options.map((option) => (
  //             <Option key={option} value={option}>
  //               {option}
  //             </Option>
  //           ))}
  //         </Select>
  //       );
  //     case "ROW":
  //     case "COLUMN":
  //     case "NUMBER":
  //       return (
  //         <Input
  //           type="text"
  //           placeholder={field.placeHolder || `Enter ${field.label}`}
  //           onChange={(e) => handleFieldChange(field.key, e.target.value)}
  //           value={fieldValue}
  //           onClick={() => setActiveIndex(index)}
  //           className={isActive ? "active-field" : ""}
  //           readOnly
  //         />
  //       );
  //     default:
  //       return (
  //         <Input
  //           placeholder={field.placeHolder || `Enter ${field.label}`}
  //           onChange={(e) => handleFieldChange(field.key, e.target.value)}
  //           value={fieldValue}
  //           readOnly
  //           className={isActive ? "active-field" : ""}
  //         />
  //       );
  //   }
  // };
  const renderFormItem = (field, index) => {
    const isActive = index === activeIndex && field.inputType !== "DROPDOWN";

    const fieldValue =
      typeof field.value === "string" ? field.value : field.value?.text || "";

    switch (field.inputType) {
      case "DROPDOWN":
        return (
          <Select
            placeholder={`Select ${field.label}`}
            onChange={(value) => handleFieldChange(field.key, value)}
            value={fieldValue || field?.allowedValues?.options?.[0]}
          >
            {field.allowedValues.options.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        );
      case "ROW":
      case "COLUMN":
      case "NUMBER":
        return (
          <Input
            type="text"
            placeholder={field.placeHolder || `Enter ${field.label}`}
            onChange={(e) => handleFieldChange(field.key, e.target.value)}
            value={fieldValue}
            onClick={() => setActiveIndex(index)}
            className={isActive ? "active-field" : ""}
            readOnly
          />
        );
      default:
        return (
          <Input
            placeholder={field.placeHolder || `Enter ${field.label}`}
            onChange={(e) => handleFieldChange(field.key, e.target.value)}
            value={fieldValue}
            className={isActive ? "active-field" : ""}
            readOnly
          />
        );
    }
  };

  const activeSheet = (sheetName = "") => {
    if (spreadsheetRef.current) {
      const sheetArray = spreadsheetRef.current?.sheets?.map(
        (item) => item.name
      );
      const targetSheetName = guidanceData?.[0]?.value?.text;
      spreadsheetRef.current.activeSheetIndex = targetSheetName
        ? sheetArray.indexOf(sheetName || targetSheetName)
        : 0;
      setSheetIndex(spreadsheetRef.current.activeSheetIndex);
    }
  };

  return (
    <div className="program-guidance-wrapper">
      <div className="title">Standardization</div>
      <div className="guidance-content">
        <SheetComponent
          file={data?.fileResponse}
          spreadsheetRef={spreadsheetRef}
          showExportButton={true}
          loadingMessage={"Fetching Document..."}
          disableMenu={false}
          activeSheet={activeSheet}
          sheetIndex={sheetIndex}
          //showBottomTabs={false}
          getCellIndexValue={getHandleIndexValue}
        />
        <div className="right-panel">
          <Form
            form={form}
            name="dynamic-json-form"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            {guidanceData.map((field, index) => (
              <Form.Item
                key={field.key}
                label={field.label}
                name={field.key}
                className={`form-feild-wrapper ${index === activeIndex ? "active-field-wrapper" : ""}`}
                rules={[
                  {
                    required: true,
                    message: `${field.label} is required`,
                  },
                  ...(field.inputType === "NUMBER"
                    ? [{ type: "text", message: "Must be a number" }]
                    : []),
                ]}
              >
                {renderFormItem(field, index)}
              </Form.Item>
            ))}
          </Form>
        </div>
      </div>
      <ButtonGroupFooter
        className="fixed-button-group"
        buttonGroupObject={buttonGroup}
      />
    </div>
  );
};

export default DecoderGuidance;
