import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Aries from "../../locale/en-Us.json";
import { setNotification } from "../../redux/reducers";
import API from "../../utils/api/api";
import { notificationTypes } from "../../utils/constants";
import { convertToUSDString, downloadBase64File } from "../../utils/utils";
import ButtonGroupFooter from "../buttonGroupFooter";
import CommanDecobarSheetComponent from "../commanDecoderSheetComponent";
import { ExportModal } from "../exportModalComponent";
import images from "../images/images";
import "./balanceSheetComponent.scss";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const BalanceSheetComponent = ({
  label,
  nextStep,
  backStep,
  companyName,
  decoderSheet,
  cikNumber,
  editAllowed,
  updateDecoderSheetData,
  financialYear,
}) => {
  const dispatch = useDispatch();
  const [openExportModal, setOpenExportModal] = useState(false);
  const [versions, setVersions] = useState([_.cloneDeep(decoderSheet)]);
  const [currentVersionIndex, setCurrentVersionIndex] = useState(0);
  const [isLoadingSpin, setIsLoadingSpin] = useState(false);
  const [decoderSheetData, setDecoderSheetData] = useState(
    versions[currentVersionIndex]
  );

  const getUpdatedBalanceSheetData = (updatedData) => {
    const fixIds = (node, parentId = "") => {
      if (parentId) {
        const correctId = `${parentId}.${node.id.split(".").pop()}`;
        node.id = correctId;
      }
      if (node.children) {
        node.children.forEach((child, index) => {
          child.id = `${node.id}.${index + 1}`;
          fixIds(child, node.id);
        });
      }
    };
    // useEffect(() => {
    //   if (!decoderSheet) {
    //     setIsLoadingSpin(true);
    //   } else {
    //     setIsLoadingSpin(false);
    //   }
    // }, [decoderSheet]);

    const dataCopy = _.cloneDeep(updatedData);
    // Fix IDs in the copied data
    dataCopy.forEach((item) => fixIds(item));

    let updatedDecoderSheet = _.cloneDeep(decoderSheetData);
    updatedDecoderSheet.balanceSheet.sheet.rows = dataCopy;

    const versionsCopy = _.cloneDeep(versions);
    const newVersions = [
      ...versionsCopy.slice(0, currentVersionIndex + 1),
      updatedDecoderSheet,
    ];
    setVersions(newVersions);
    setCurrentVersionIndex(newVersions.length - 1);
    setDecoderSheetData(updatedDecoderSheet);
    updateDecoderSheetData(updatedDecoderSheet);
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if ((e.ctrlKey || e.metaKey) && !e.shiftKey && e.code === "KeyZ") {
        e.preventDefault();
        handleUndo();
      } else if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.code === "KeyZ") {
        e.preventDefault();
        handleRedo();
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [decoderSheetData]);

  const handleUndo = (e) => {
    if (currentVersionIndex > 0) {
      setCurrentVersionIndex(currentVersionIndex - 1);
      updateDecoderSheetData(versions[currentVersionIndex - 1]);
      setDecoderSheetData(versions[currentVersionIndex - 1]);
    }
    if (currentVersionIndex === 0) {
      setDecoderSheetData([_.cloneDeep(decoderSheet)][0]);
    }
  };

  useEffect(() => {
    let decoderSheetData = _.cloneDeep(versions[currentVersionIndex]);
    setDecoderSheetData(decoderSheetData);
  }, [currentVersionIndex]);

  const handleRedo = () => {
    if (currentVersionIndex < versions.length - 1) {
      setCurrentVersionIndex(currentVersionIndex + 1);
      updateDecoderSheetData(versions[currentVersionIndex + 1]);
      setDecoderSheetData(versions[currentVersionIndex + 1]);
    }
  };

  const exportDecoderSheet = async () => {
    const url = "v1/decoder/" + cikNumber.projectId + "/period/download";
    const response = await API({
      method: "POST",
      path: url,
      data: {
        ...versions[currentVersionIndex],
        type: "BALANCE_SHEET",
      },
      dispatch,
    });
    const file = downloadBase64File(response?.data);
    onClose();
    if (file) {
      dispatch(
        setNotification({
          type: notificationTypes.SUCCESS,
          message: "File Downloaded Successfully",
          placement: "bottom",
        })
      );
    }
  };

  const onClose = () => {
    setOpenExportModal(false);
  };
  const handleOpenExportModal = () => {
    setOpenExportModal(true);
  };

  const jsonData = {
    table: {
      columns: [
        {
          title: decoderSheetData?.balanceSheet?.mappedKey,
          dataIndex: "mappedKey",
          key: "mappedKey",
          editable: true,
          width: 400,
        },
        {
          title: decoderSheetData?.balanceSheet?.reportKey,
          dataIndex: "reportKey",
          key: "reportKey",
          editable: true,
          width: 350,
        },
        {
          title: (
            <div className="sheet-date1">
              <div className="sheet-date-duration">
                {decoderSheetData?.balanceSheet?.sheet.duration}
              </div>
              {decoderSheetData?.balanceSheet?.sheet.date1}
            </div>
          ),
          dataIndex: "date1",
          key: "date1",
          editable: true,
          width: 180,
          render: (text, record) =>
            record.date1 ? convertToUSDString(text) : null,
        },
        {
          title: decoderSheetData?.balanceSheet?.sheet.date2,
          dataIndex: "date2",
          key: "date2",
          editable: true,
          width: 180,
          render: (text, record) => convertToUSDString(Number(text)),
        },
        {
          title: decoderSheetData?.balanceSheet?.comment,
          dataIndex: "comment",
          key: "comment",
          width: 100,
        },
      ],

      dataSource: decoderSheetData?.balanceSheet?.sheet?.rows.map((row) => ({
        ...row,
        key: row.id,
      })),
    },
  };

  const saveDecoderSheet = async (decoderSheetData, status, editAllowed) => {
    try {
      if (!editAllowed) {
        backStep();
        return;
      }
      setIsLoadingSpin(true);
      const url = `v1/decoder/${cikNumber.projectId}/decode${
        financialYear ? `?financialYear=${financialYear}` : ""
      }`;
      const result = await API({
        method: "POST",
        path: url,
        data: {
          ...decoderSheetData,
          jsonStatus: status,
          type: "BALANCE_SHEET",
        },
        dispatch,
      });
      if (result.data) {
        setIsLoadingSpin(false);
        backStep("refreshTrackApi");
        if (status === "DECODED") {
          dispatch(
            setNotification({
              type: notificationTypes.SUCCESS,
              message: "Decoded Successfully",
              placement: "bottom",
            })
          );
          return;
        }
        dispatch(
          setNotification({
            type: notificationTypes.SUCCESS,
            message: "Saved Draft Successfully",
            placement: "bottom",
          })
        );
      } else {
        dispatch(
          setNotification({
            type: notificationTypes.ERROR,
            placement: "bottom",
          })
        );
        setIsLoadingSpin(false);
      }
    } catch (error) {}
  };

  // json display bottom buttons
  const buttonGroup = {
    left: [
      {
        text: Aries.BUTTON_GLOBAL_SAVE_DRAFT,

        className: "secondary-buttons",
        fn: () =>
          saveDecoderSheet(
            versions[currentVersionIndex],
            "DRAFTED",
            editAllowed
          ),
        imageLink: images["document-text.svg"],
        disabled: !editAllowed,
      },
      {
        text: Aries.BUTTON_GLOBAL_EXPORT,
        className: "secondary-buttons",
        fn: handleOpenExportModal,
        imageLink: images["document-text.svg"],
      },
    ],
    right: [
      {
        text: Aries.BUTTON_GLOBAL_BACK,
        name: "back",
        className: "back-button",
        imageLink: images["arrow-left.svg"],
        fn: backStep,
      },
      {
        text: Aries.BUTTON_GLOBAL_FINISH,
        className: "primary-button",
        rightImageLink: images["arrow-right.svg"],
        fn: () => saveDecoderSheet(decoderSheetData, "DECODED", editAllowed),
        disabled: isLoadingSpin,
      },
    ],
  };

  return (
    <div className="balance-container ">
      {" "}
      <Spin
        spinning={isLoadingSpin}
        indicator={<LoadingOutlined style={{ fontSize: 36 }} />}
        fullscreen
        className="fullscreen-loader"
      />{" "}
      <div className="wrapper-label">
        <img
          className="user-icon"
          src={images["aistra-vaa-avatar-1.svg"]}
          loading="lazy"
          alt="User icon"
        />
        <div
          className="label"
          dangerouslySetInnerHTML={{ __html: label }}
        ></div>
      </div>
      <div className="heading-wrapper">
        <div className="left-heading">Balance Sheet</div>
        <div className="right-heading-group">
          <div className="right-heading">
            Company: <span className="heading-value">{companyName}</span>
          </div>
          <div className="right-heading">
            Document Type:{" "}
            <span className="heading-value">{decoderSheet.documentType}</span>
          </div>
          <div className="right-heading">
            Statement Type: <span className="heading-value">Balance Sheet</span>
          </div>
          {decoderSheetData?.balanceSheet?.sheet?.unit && (
            <div className="right-heading">
              Unit:{" "}
              <span className="heading-value">
                {decoderSheetData?.balanceSheet?.sheet?.unit}
              </span>
            </div>
          )}
        </div>
      </div>
      <CommanDecobarSheetComponent
        sheetTableData={jsonData}
        decoderSheetname={"Balance Sheet"}
        getUpdatedSheetData={getUpdatedBalanceSheetData}
        optionMap={decoderSheet?.optionsMap}
        editAllowed={editAllowed}
      />
      <ButtonGroupFooter
        className="fixed-button-group"
        buttonGroupObject={buttonGroup}
      />
      <ExportModal
        visible={openExportModal}
        onClose={onClose}
        handleDownloadExcel={() => exportDecoderSheet()}
      />
    </div>
  );
};
export default BalanceSheetComponent;
