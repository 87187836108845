import React, { useEffect, useState } from "react";
import { Card, Modal, Radio, Select, Space } from "antd";
import { useDispatch } from "react-redux";
import { getFinancialYearsPvtIntApi } from "@pages/decoder-v2/api";
import { isPrivateOrInternationalCompany } from "@utils/utils";

const DecoderDataUploadModal = ({
  showModel,
  handleClose,
  uploadTrialBalanceSheet,
  uploadDecoderSheet,
  setState,
  companyType,
  projectId,
}) => {
  const dispatch = useDispatch();
  // State to track the selected option
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const fetchFinancialYears = async () => {
      if (!companyType || !projectId) return;

      if (isPrivateOrInternationalCompany(companyType)) {
        try {
          const data = await getFinancialYearsPvtIntApi(
            `/onboarding/${projectId}/financial-years`,
            dispatch
          );

          if (isMounted) {
            setYearOptions(
              data?.financialYears?.map((item) => ({
                label: item.displayYear,
                value: item.period,
              })) || []
            );
          }
        } catch (error) {
          console.error("Error fetching financial years:", error);
        }
      }
    };

    fetchFinancialYears();

    return () => {
      isMounted = false; // Cleanup function to prevent state updates on unmount
    };
  }, [companyType, projectId, dispatch]);

  const handleCancel = () => {
    handleClose();
  };

  const handleProceed = () => {
    if (selectedOption === 1) {
      uploadDecoderSheet(); // Execute uploadDecoderSheet for option 1
    } else if (selectedOption === 2) {
      uploadTrialBalanceSheet(); // Execute uploadTrialBalanceSheet for option 2
    }

    setState((prev) => ({
      ...prev,
      financialYear: selectedYear,
    }));
    handleClose();
  };

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value); // Update selected option
  };

  const handleYearChange = (value) => {
    setSelectedYear(value); // Update selected financial year
  };

  return (
    <Modal
      open={showModel}
      centered
      width={600}
      destroyOnClose
      style={{ overflow: "auto" }}
      onCancel={handleCancel}
      footer={[]}
      rootClassName="decoder-flow-selector-wrapper"
    >
      <div className="decoder-flow-selector-model">
        <div className="decoder-flow-selector-title">Decoder Flow Selector</div>
        {isPrivateOrInternationalCompany(companyType) && (
          <div className="decoder-flow-selector-financial-year">
            <p className="year-heading">Select Financial Year:</p>
            <Select
              style={{ minWidth: "220px" }}
              options={yearOptions}
              onChange={handleYearChange}
              placeholder="Select Financial Year"
            />
          </div>
        )}
        <div className="decoder-flow-selector-details">
          <Radio.Group onChange={handleRadioChange}>
            <Space direction="vertical">
              <Card
                className={`radio-wrapper ${selectedOption === 1 ? "selected" : ""}`}
              >
                <div className="info">
                  <p className="flow-heading">Unpublished Financials</p>
                  <p className="flow-description">
                    If you have draft financial statement select this option
                  </p>
                </div>
                <Radio value={1} />
              </Card>

              <Card
                className={`radio-wrapper ${selectedOption === 2 ? "selected" : ""}`}
              >
                <div className="info">
                  <p className="flow-heading">
                    Trial Balance and Chart of Account
                  </p>
                  <p className="flow-description">
                    If you have draft trial balance select this option
                  </p>
                </div>
                <Radio value={2} />
              </Card>
            </Space>
          </Radio.Group>
        </div>

        <div className="decoder-flow-selector-footer">
          <button className="button white-button" onClick={handleCancel}>
            Cancel
          </button>
          <button
            className="primary-button button"
            onClick={handleProceed}
            disabled={selectedOption === null} // Disable if no option is selected
          >
            Proceed to Upload
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DecoderDataUploadModal;
