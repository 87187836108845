import React, { memo, useEffect, useState, useRef } from "react";
import { createRoot } from "react-dom/client";
import isEqual from "lodash/isEqual";
import {
  SpreadsheetComponent,
  getIndexesFromAddress,
  getCell,
} from "@syncfusion/ej2-react-spreadsheet";
import { Empty, Skeleton, Popover } from "antd";
import { ReactTyped } from "react-typed";
import "./sheetComponent.scss";
import { syncfusionSpreedSheetBaseUrl } from "../api/api";
import { focus } from "@syncfusion/ej2-react-spreadsheet";
import API from "../../utils/api/api";
import FallbackPlaceholder from "../../utils/components/fallback";
import { Select } from "antd";
import images from "../images/images";
import { imagesIconarr } from "./signImages";
const { Option } = Select;
function SheetComponent({
  getValueOnClick = () => {},
  getCellIndexValue = () => {},
  handleSaveSheetCb = () => {},
  file,
  spreadsheetRef,
  loading = true,
  loadingMessage = "",
  className = "",
  onOpenActionCb = () => {},
  handleHideRowsOnSheetOpen = () => {},
  beforeSaveCb = () => {},
  saveCompleteCb = () => {},
  showExportButton = false,
  activeSheet = () => {},
  showBottomTabs = true,
  sheetIndex = 0,
  enableVirtualization = false,
  loadingsheetStatus = () => {},
  disableMenu = true,
  highlightCellValue = [1, 1],
  hideMenuBar = false,
}) {
  const [isSheetOpening, setIsSheetOpening] = useState(false);
  const [openFileResult, setOpenFileResult] = useState(null);
  const [openFailed, setOpenFailed] = useState(false);
  const abortControllerRef = useRef(null);
  const disableheader = false;

  useEffect(() => {
    if (file && file.file) {
      setOpenFileResult(null);
      openExcel({
        file: file.file,
        extension: "xlsx",
        password: "",
      });
    }
  }, [file?.file]);

  useEffect(() => {
    highlightCell(highlightCellValue?.[0], highlightCellValue?.[1]);
  }, [highlightCellValue?.[0], highlightCellValue?.[1]]);

  const getColumnLetter = (colIndex) => {
    let letter = "";
    while (colIndex > 0) {
      let remainder = (colIndex - 1) % 26;
      letter = String.fromCharCode(65 + remainder) + letter;
      colIndex = Math.floor((colIndex - 1) / 26);
    }
    return letter;
  };

  const onClick = (args) => {
    let valuesArray = [];
    let allValuesArray = [];
    let index = getIndexesFromAddress(args.range);
    getColumnLetter(index[1] + 1);
    spreadsheetRef.current
      ?.getData(
        "A" +
          (index[0] + 1) +
          ":" +
          getColumnLetter(index[1] + 1) +
          (index[0] + 2)
      )
      .then((data) => {
        const displayTextsArray = [...data.values()].map((entry, i) => {
          const rowIndex = index[0];
          const colIndex = i;

          const cell = getCell(
            rowIndex,
            colIndex,
            spreadsheetRef.current?.getActiveSheet()
          );
          const displayText = spreadsheetRef.current?.getDisplayText(cell);
          return displayText || entry.value;
        });

        const valuesArray = displayTextsArray.filter(
          (value) => value !== undefined
        );

        getValueOnClick(valuesArray);
        getCellIndexValue(index, args, displayTextsArray); // Pass all values (including undefined if needed)
      });
  };

  const onCreated = async () => {
    if (!spreadsheetRef.current) return;
    await new Promise((resolve) => setTimeout(resolve, 100));

    if (openFileResult?.data?.Workbook?.sheets) {
      spreadsheetRef.current.openFromJson({ file: openFileResult.data });
      handleSaveSheetCb({ action: "openSheet" }, getSheetFromJson);
    }
    spreadsheetRef.current.activeSheetIndex = sheetIndex;
    spreadsheetRef.current.showSheetTabs = showBottomTabs;
    spreadsheetRef.current.allowEditing = disableMenu;
    focus(spreadsheetRef.current.element);
    spreadsheetRef.current.enableRibbonTabs(["Home", "Formula", "View"], false);
    spreadsheetRef.current.hideToolbarItems("Data", [0, 1, 2, 3, 4]);
    spreadsheetRef.current.hideRibbonTabs([
      "Formulas",
      "View",
      "Insert",
      "File",
      ...(hideMenuBar ? ["Home", "Data"] : []),
    ]);

    spreadsheetRef?.current?.addToolbarItems(
      "Data",
      [
        {
          id: "custombtn",
          tooltipText: "Custom Btn",
          template: appendDropdownBtn("custombtn"),
        },
      ],
      7
    );

    // activeSheet();
  };
  const handleImageChange = (value) => {
    const image = imagesIconarr.find((img) => img.id === value);
    if (spreadsheetRef.current && image) {
      spreadsheetRef.current.insertImage([
        { src: image.url, width: 25, height: 25 },
      ]);
    }
  };
  const content = (
    <div className="sheet-custom-option-icons">
      {imagesIconarr.map((item) => (
        <img
          src={item.url}
          onClick={() => handleImageChange(item.id)}
          alt="icon"
        />
      ))}
    </div>
  );
  const appendDropdownBtn = (id) => {
    const dropdownElement = (
      <Popover placement="bottomRight" content={content}>
        <div className="sheet-custom-icon">
          <img src={images["omega-circle.svg"]} alt="Omega circle" /> Tick Sign
        </div>
      </Popover>
    );

    const container = document.createElement("div");
    container.id = id;
    const root = createRoot(container);
    root.render(dropdownElement);

    return container;
  };
  const openExcel = async (requestData) => {
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      const abortController = new AbortController();
      abortControllerRef.current = abortController;
      setIsSheetOpening(true);
      const url = syncfusionSpreedSheetBaseUrl + "open";
      const result = await API({
        method: "POST",
        path: url,
        data: JSON.stringify(requestData),
      });
      setOpenFileResult(result || null);
      setIsSheetOpening(false);
      loadingsheetStatus(false);
    } catch (error) {
      setIsSheetOpening(false);
      loadingsheetStatus(true);
      console.error("Error in GET request:", error);
    }
  };
  const getSheetFromJson = async () => {
    const json = await spreadsheetRef.current.saveAsJson();
    return json;
  };

  const EmptyView = () => {
    return (
      <div className="loading-sheet">
        <div className="loading-skeleton">
          <Empty />
        </div>
      </div>
    );
  };

  const highlightCell = (rowIndex, colIndex) => {
    if (spreadsheetRef.current) {
      const sheet = spreadsheetRef.current;
      const cellAddress = `${String.fromCharCode(65 + colIndex)}${rowIndex + 1}`;
      sheet.cellFormat({ backgroundColor: "#fff" });
      sheet.cellFormat(
        { backgroundColor: "var(--Colors-PRIMARY-GREEN-50)" },
        cellAddress
      );
      sheet.goTo(cellAddress);
      sheet.selectRange(cellAddress);
    }
  };

  return (
    <div tabIndex="1" className={`spredSheet-container ${className}`}>
      {openFileResult ? (
        <SpreadsheetComponent
          select={onClick}
          ref={spreadsheetRef}
          actionComplete={(args) => handleSaveSheetCb(args, getSheetFromJson)}
          saveComplete={(args) => saveCompleteCb(args, spreadsheetRef)}
          beforeSave={(eventArgs) => beforeSaveCb(eventArgs, spreadsheetRef)}
          // fileMenuBeforeOpen={() =>
          //   spreadsheetRef.current?.enableFileMenuItems(
          //     ["New", "Open", "Print"],
          //     false,
          //     false
          //   )
          // }
          created={onCreated}
          openComplete={onOpenActionCb}
          dataBound={handleHideRowsOnSheetOpen}
          scrollSettings={{ isFinite: true }}
          allowScrolling={true}
        />
      ) : loading || isSheetOpening ? (
        <div className="loading-sheet">
          <div className="loading-skeleton">
            <Skeleton active />
            <ReactTyped
              strings={[loadingMessage]}
              typeSpeed={25}
              style={{ color: "var(--black-color)" }}
            />
            <Skeleton active />
          </div>
        </div>
      ) : openFailed ? (
        <FallbackPlaceholder />
      ) : (
        <EmptyView />
      )}
    </div>
  );
}
export default memo(SheetComponent, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);
